import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Fade, Link, MenuItem } from '@mui/material';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import { IMenuItemNode } from '@orgnc/core/lib/wordpress/api/adapters/menuDataAdapter';
import React, { useState } from 'react';
import { OptionalLinkWrapper } from '../optional-link-wrapper/optional-link-wrapper';
import {
  StyledDesktopMenuBox,
  StyledDesktopMenuLink,
  StyledDesktopSubMenu,
} from './desktop-menu-style';

export const DesktopMenu = ({
  aNavigations,
}: {
  aNavigations: IMenuItemNode[];
}) => {
  const [openSubMenu, setOpenSubMenu] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleOpen = (parentMenuId: string) => {
    setOpenSubMenu({
      ...openSubMenu,
      [parentMenuId]: true,
    });
  };

  const handleClose = (parentMenuId: string) => {
    setOpenSubMenu({
      ...openSubMenu,
      [parentMenuId]: false,
    });
  };

  return (
    <StyledDesktopMenuBox>
      {aNavigations.map(
        ({ children, id, label, path }: IMenuItemNode, index) => {
          const hasChildren = children && children.length > 0;
          const open = hasChildren && openSubMenu[id];

          const props: any = hasChildren
            ? {
                onClick: () => handleOpen(id),
                endIcon: <KeyboardArrowDown fontSize="medium" />,
                'aria-controls': open ? 'fade-menu' : undefined,
                'aria-haspopup': 'true',
                'aria-expanded': open ? 'true' : undefined,
              }
            : {
                component: 'a',
              };

          const isRootButtonLink = !hasChildren;
          return (
            <React.Fragment key={`menu_navigation_${index}`}>
              <OptionalLinkWrapper
                isLink={isRootButtonLink}
                href={(path as string) || '#'}
              >
                <StyledDesktopMenuLink
                  id={id}
                  className={open ? 'active' : ''}
                  {...props}
                >
                  {label}
                </StyledDesktopMenuLink>
              </OptionalLinkWrapper>

              {hasChildren && open && (
                <StyledDesktopSubMenu
                  anchorEl={document.getElementById(id as string)}
                  open={true}
                  onClose={() => handleClose(id)}
                  TransitionComponent={Fade}
                  disableScrollLock
                >
                  {children.map((subMenu) => (
                    <MenuItem
                      key={`sub_menu_${subMenu.parentId}_${subMenu.id}`}
                      onClick={() => handleClose(id)}
                    >
                      <DisablePrefetchLink
                        href={(subMenu.path as string) || '#'}
                        passHref
                      >
                        <Link>{subMenu.label}</Link>
                      </DisablePrefetchLink>
                    </MenuItem>
                  ))}
                </StyledDesktopSubMenu>
              )}
            </React.Fragment>
          );
        }
      )}
    </StyledDesktopMenuBox>
  );
};
